<template>
  <div>
    <Header />
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="exit-inform">
            <button class="arraw-exit" @click="exit()">
              <img src="../../assets/img/arraw-left.svg" alt="" srcset="" />
            </button>
            <span>Назад</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="main-box">
            <div class="head-buttons">
              <button
                class="buttons"
                :class="{ active: bankInfo }"
                @click="getBankInfo()"
              >
                Профиль
              </button>
              <button
                class="buttons"
                :class="{ active: arhiveInfo }"
                @click="getBankArhive()"
              >
                Архив договоров
              </button>
              <button class="buttons" :class="{ active: bankTel }" @click="getbankTel()">
                Контакты специалистов
              </button>
            </div>
            <div class="information" v-if="bankInfo">
              <div class="info">
                <div class="name">{{ bankInformation.name }}</div>
                <!-- <img src="../../assets/img/pencil.svg" alt="" srcset="" /> -->
                <!-- <img src="../../assets/img/delete.svg" alt="" srcset="" /> -->
                <span @click="blockBank()" v-if="!bankInformation.blocked_at"
                  ><img class="block" src="../../assets/img/lock.svg" alt="" srcset="" />
                  Заблокировать организацию</span
                >
                <span @click="reblockBank()" v-else
                  ><img src="./../../assets/img/Vector.svg" alt="" srcset="" />
                  Разблокировать организацию</span
                >
              </div>
              <div class="info-adres">
                <span class="item">{{ bankInformation.phone }}</span
                ><span class="item">{{ bankInformation.email }}</span>

                <span class="item adres">{{ bankInformation.address }}</span>
                <span class="item block" v-if="bankInformation.blocked_at"
                  >заблокирован</span
                >
              </div>
              <div class="information-bank">
                <table>
                  <tr>
                    <td class="line-info">
                      БИН
                      <div class="dashed"></div>
                    </td>
                    <td>{{ bankInformation.bin }}</td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      ИИК
                      <div class="dashed"></div>
                    </td>
                    <td>KZ456017111000012949</td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      БИК
                      <div class="dashed"></div>
                    </td>
                    <td>HSBKKZKX</td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      Председатель Правления
                      <div class="dashed"></div>
                    </td>
                    <td>Миронов Павел Владимирович</td>
                  </tr>

                  <tr>
                    <td class="line-info">
                      Совет директоров
                      <div class="dashed"></div>
                    </td>
                    <td>
                      Ким Вячеслав Константинович (Председатель), Ломтадзе Михеил
                      Нугзарович, Рахметов Чингис Жолдасбаевич (независимый директор)
                    </td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      Банк второго уровня
                      <div class="dashed"></div>
                    </td>
                    <td>Лицензия: №1.2.245/61 от 03.02.2020г.</td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      Брокеры-дилеры
                      <div class="dashed"></div>
                    </td>
                    <td>Лицензия: №1.2.245/61. Дата: 2020-02-03</td>
                  </tr>
                  <tr>
                    <td class="line-info">
                      Банковские холдинги
                      <div class="dashed"></div>
                    </td>
                    <td>АО Kaspi Group, АО Kaspi.kz</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="information" v-if="arhiveInfo">
              <BankContracts :id="$route.params.bankId" />
            </div>
            <div class="information" v-if="bankTel">
              <div class="fl">
                <div class="persone-information" v-for="item of bankUsers" :key="item.id">
                  <p class="fio">{{ item.common_name }}</p>
                  <p class="job">{{ item.role }}</p>
                  <div v-if="item.phone">
                    <img src="../../assets/img/tel.svg" alt="" srcset="" />
                    <span class="tel">{{ item.phone }}</span>
                  </div>
                  <img src="../../assets/img/mail.svg" alt="" srcset="" /><span
                    class="tel"
                    >{{ item.email }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header";
import BankContracts from "../../components/AdminPanel/BankContracts";
export default {
  components: { Header, BankContracts },
  data() {
    return {
      bankInfo: true,
      arhiveInfo: false,
      bankTel: false,
      currentById: 1,
      bankInformation: {},
      bankUsers: [],
    };
  },
  methods: {
    getToday() {
      let today = new Date();
      let date =
        today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

      return date;
    },
    async getBank() {
      try {
        this.$axios({
          method: "get",
          url:
            this.$API_URL + this.$API_VERSION + `admin/organizations/${this.currentById}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
          },
        }).then((response) => {
          console.log("data", response.data);
          this.bankInformation = response.data.data;
          this.bankUsers = response.data.data.user;
        });
      } catch (error) {
        console.error(error);
      }
    },
    getbankTel() {
      this.bankTel = true;
      this.bankInfo = false;
      this.arhiveInfo = false;
    },
    getBankArhive() {
      this.arhiveInfo = true;
      this.bankTel = false;
      this.bankInfo = false;
    },
    getBankInfo() {
      this.bankInfo = true;
      this.arhiveInfo = false;
      this.bankTel = false;
    },
    exit() {
      this.$router.push({ name: "adminPanel" });
    },
    async blockBank() {
      try {
        this.$axios({
          method: "patch",
          url:
            this.$API_URL +
            this.$API_VERSION +
            "admin/organizations/" +
            this.currentById +
            "/block",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
          },
        }).then((response) => {
          this.getBank();
          console.log(response);
        });
      } catch (error) {
        console.log(error);
        alert("Ошибка сервера");
      }
    },
    async reblockBank() {
      try {
        this.$axios({
          method: "patch",
          url:
            this.$API_URL +
            this.$API_VERSION +
            "admin/organizations/" +
            this.currentById +
            "/unblock",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token_kcmr")}`,
          },
        }).then((response) => {
          this.getBank();
          console.log(response);
        });
      } catch (error) {
        console.log(error);
        alert("Ошибка сервера");
      }
    },
  },
  mounted() {
    this.currentById = this.$route.params.bankId;
    this.getBank();
    console.log("istep tur");
  },
};
</script>
<style lang="scss">
.exit-inform {
  display: flex;
  margin-top: 40px;
  margin-bottom: 35px;
  align-items: baseline;
  span {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #1e2321;
    font-weight: 400;
  }
}
.arraw-exit {
  position: relative;
  background: #135334;
  width: 42px;
  height: 42px;
  border-radius: 50%;

  margin-right: 10px;
  img {
    left: 50%;
    top: 50%;
  }
}
.main-box {
  box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
  border-radius: 9px;
  padding: 0 45px 59px 45px;
  font-family: Gilroy;
  .head-buttons {
    width: min-content;
    white-space: nowrap;
    margin: auto;
    transform: translateY(-50%);
    transition: 0.3s ease;
    .buttons {
      transition: 0.3 ease;
      margin-right: 10px;
      padding: 16px 40px;
      box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
      border-radius: 45px;
      white-space: nowrap;

      img {
        path {
          fill: white;
        }
      }
    }
    .active {
      transition: 0.3s ease;
      background: #135334;
      color: #ffffff;
    }
  }
  .information {
    .fl {
      font-family: Gilroy;
      display: flex;
      flex-wrap: wrap;
      .persone-information {
        box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
        border-radius: 9px;
        background: #ffffff;
        width: 30%;
        margin: 22px;
        padding: 32px 68px 31px 68px;
      }
      .fio {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: #1e2321;
        margin-bottom: 0px;
      }
      .job {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #edf0f5;
        margin-bottom: 13px;
      }
      img {
        margin-right: 11px;
      }
      .tel {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #aa7530;
      }
    }
    margin-top: 36px;
    .info-adres {
      margin-bottom: 42px;

      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      .item {
        margin-right: 20px;
        color: #aa7530;
      }
      .adres {
        color: #1e2321;
      }
      .block {
        color: rgba(255, 14, 14, 0.644);
      }
    }
    .info {
      background-color: transparent !important;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      :hover {
        cursor: pointer;
      }
      img {
        margin-right: 30px;
      }
      .block {
        filter: invert(1);
        margin-right: 8px;
      }
      .name {
        padding: 10px 25px;
        background: #aa7530;
        border-radius: 9px;
        margin-right: 30px;
        font-weight: 700;
        color: #ffffff;
      }
      span {
        margin-right: 16px;
      }
    }
    .option {
      display: flex;
      margin-bottom: 30px;
      align-items: baseline;
      .download {
        margin-left: auto;
        background: #aa7530;
        border-radius: 39px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        padding: 10px 35px;
        color: #ffffff;
      }
      .configuration {
        display: flex;
        align-items: center;

        .input-box {
          background: #f9f9f9;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          border-radius: 9px;
          width: 705px;
          white-space: nowrap;
          padding: 9px 10px;
          margin-right: 47px;

          input {
            width: 96%;
          }
          img {
            margin-right: auto;
          }
        }
      }
      .configuration-restr {
        display: flex;
        align-items: center;
        width: 100%;
        .type {
          width: 235px !important;
        }
        .bank {
          width: 323px !important;
        }
        .period {
          width: 159px !important;
        }
        .input-box {
          background: #f9f9f9;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          border-radius: 9px;
          width: 50%;
          white-space: nowrap;
          padding: 9px 10px;

          input {
            width: 96%;
          }
          img {
            margin-right: auto;
          }
        }
        label {
          margin-right: 10px;
        }

        .radio-box {
          label {
            margin-right: 28px;
          }
          font-size: 14px;
          color: #edf0f5;
        }
      }
      .header-button {
        font-family: Gilroy;
        position: absolute;
        left: calc(50% - 180px);
        width: min-content;
        right: 50%;
        display: flex;
        top: 0;
        transform: translateY(-50%);
      }
      & .buttons {
        transition: 0.3 ease;
        margin-right: 10px;
        padding: 16px 40px;
        box-shadow: 0px 8px 50px rgba(0, 0, 0, 0.04);
        border-radius: 45px;
        white-space: nowrap;
        img {
          path {
            fill: white;
          }
        }
      }
      .active {
        transition: 0.5s ease;
        background: #135334;
        color: #fff;
        fill: white;
      }
    }
  }
  .information-bank {
    .dashed {
      border-bottom: 1px dashed #bcc6d7;
      width: 100%;
      margin-right: 10px;
    }
    table {
      td {
        margin-bottom: 21px;
        font-weight: 600;
      }
      .line-info {
        display: flex;
        width: 380px;
        white-space: nowrap;
        font-weight: 600;
        color: #bcc6d7;
      }
    }
  }
}
</style>
